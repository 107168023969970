import React from 'react';
import { Client as Styletron } from 'styletron-engine-atomic';
import { Provider as StyletronProvider } from 'styletron-react';
import { BaseProvider, createLightTheme } from 'baseui';
import './App.css';
import { PLACEMENT, SnackbarProvider } from 'baseui/snackbar';

const engine = new Styletron();

const LightTheme = createLightTheme(
  {
    primaryFontFamily:
      '"IBM Plex Sans", system-ui, "Helvetica Neue", Helvetica, Arial, sans-serif',
  },
  {
    borders: {
      useRoundedCorners: true,
      buttonBorderRadius: '4px',
      inputBorderRadius: '4px',
      popoverBorderRadius: '8px',
      surfaceBorderRadius: '4px',
    },
  }
);

export interface AppProps {
  children: React.ReactNode;
}

function App({ children }: AppProps) {
  return (
    <StyletronProvider value={engine}>
      <BaseProvider
        overrides={{
          AppContainer: { style: { height: '100%' } },
        }}
        theme={LightTheme}
      >
        <SnackbarProvider
          overrides={{ Root: { style: { zIndex: 999999 } } }}
          placement={PLACEMENT.bottom}
        >
          {children}
        </SnackbarProvider>
      </BaseProvider>
    </StyletronProvider>
  );
}

export default App;
