import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './Utils/firebase-setup';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import App from './App';
import { StyledSpinnerNext } from 'baseui/spinner';
import { Block } from 'baseui/block';
import { SearchRecord } from '@/Features/RecordManager/Component/SearchRecord';

ReactDOM.render(
  <React.StrictMode>
    <App>
      <BrowserRouter>
        <Suspense
          fallback={
            <Block
              minHeight="100vh"
              display="flex"
              alignItems="center"
              placeContent="center"
            >
              <StyledSpinnerNext />
            </Block>
          }
        >
          <Routes>
            <Route
              path="/"
              element={
                <Block
                  paddingTop="scale1200"
                  paddingLeft="18px"
                  paddingRight="18px"
                >
                  <SearchRecord/>
                </Block>
              }
            />
          </Routes>
        </Suspense>
      </BrowserRouter>
    </App>
  </React.StrictMode>,
  document.getElementById('root')
);
