import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Input } from 'baseui/input';
import { Block } from 'baseui/block';
import { Search } from 'baseui/icon';
import { useStyletron } from 'baseui';
import { ListItem } from 'baseui/list';
import { Button, KIND } from 'baseui/button';
import { LabelMedium, LabelXSmall } from 'baseui/typography';
import { MeiliSearch, SearchResponse } from 'meilisearch';

const client = new MeiliSearch({
  host: 'https://www.nrtcfresh.com/api/v1/search',
  apiKey: 'Ic0AE3uptIkjWSEpfLj7IIyNk3jtwg8V9290jr6gq1'
});

export function SearchRecord({
                               onChange,
                               isFullWidth,
                               limit = 5
                             }: { onChange?: () => void; isFullWidth?: boolean; limit?: number; }) {
  const [records, setRecords] = useState<SearchResponse<Record<string, any>> | null>();
  const [searchTerm, setSearchTerm] = useState('');
  const [offset, setOffset] = useState(20);

  const [css] = useStyletron();

  const search = useCallback(
    (searchTerm_, limit_, offset_) => {
      client.index('voter').search(searchTerm_, {
        limit: limit_,
        offset: offset_
      }).then(docs => {
        if (docs.query === searchTerm_) {
          setRecords(docs);
        }
      });
    },
    [setRecords]
  );

  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    ref.current?.focus();

    ref.current?.scrollIntoView({
      inline: 'center',
      block: 'center',
      behavior: 'smooth'
    });

    // eslint-disable-next-line
  }, [ref.current]);

  useEffect(() => {
    if (records?.query === searchTerm) {
      search(searchTerm, limit, offset);
      return;
    }

    search(searchTerm, limit, 0);

    // eslint-disable-next-line
  }, [search, offset, searchTerm]);

  return (
    <Block maxWidth={isFullWidth ? '100%' : '800px'} margin='auto'>
      <>
        <Input
          inputRef={ref}
          placeholder='Search from Voter List'
          endEnhancer={<Search size={20} />}
          value={searchTerm}
          onChange={({ target }) => {
            setSearchTerm((target as HTMLInputElement).value);
            setOffset(0);
          }}
          clearable={true}
        />

        <ul
          className={css({
            marginLeft: '-12px',
            marginRight: '-12px',
            marginTop: '12px',
            padding: 0
          })}
        >
          {records?.hits.map((res) => {
            return (
              <ListItem
                key={res.id}
              >
                <Block
                  $style={{
                    flexGrow: 1,
                    display: 'flex',
                    flexDirection: 'column'
                  }}
                >
                  <span className={css({ width: '100%', fontFamily: "'IBM Plex Sans Devanagari', sans-serif" })}>
                    {res.Name} ({res.Age} {res.Gender}) {res.Address} ({res.AntimYadiSr}-)({res.NewMemNo}-)
                  </span>
                  <LabelMedium>

                  </LabelMedium>

                  <LabelMedium $style={{ color: '#666', marginLeft: '12px' }}>

                  </LabelMedium>
                </Block>

                <div className={css({ display: 'flex' })}>
                  <span className={css({ flexGrow: 1 })} />
                  <LabelXSmall
                    as='abbr'
                    $style={{ color: '#444' }}
                    marginTop='scale400'
                    marginBottom='scale400'
                  >
                  </LabelXSmall>
                </div>
              </ListItem>
            );
          })}
        </ul>
      </>

      <Block display='flex'>
        <Button
          kind={KIND.secondary}
          type='button'
          $style={{ width: '100%', marginBottom: '12px' }}
          disabled={offset === 0}
          onClick={() => {
            setOffset((prev) => prev - 10);
          }}
        >
          Previous Page
        </Button>

        <Block width='12px' />

        <Button
          kind={KIND.secondary}
          type='button'
          $style={{ width: '100%', marginBottom: '12px' }}
          onClick={() => {
            setOffset((prev) => prev + 10);
          }}
        >
          Next Page
        </Button>
      </Block>
    </Block>
  );
}
